import * as React from "react";
import { Content } from "@lib/components";
import { ButtonGroup } from "@lib/components";
import { RestaurantMenuList } from "./list.menus";
import { RestaurantOptionSetList } from "./list.option_sets";
import { RestaurantDishTags } from "./list.tags";
import { RestaurantMenuTemplates } from "./templates";
import { RestaurantMenuUploadService } from "./upload-service";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../../mobx/components";
import { Button } from "@lib/components";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from "@lib/components";

interface Props { }
interface State {
	tab: string;
}

@inject("store") @observer
export class RestaurantMenus extends MobxComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			tab: "0",
		};
	}

	componentDidMount(): void {
		this.injected.store.getRestaurantStock(this.injected.store.restaurant!._id);
	}

	render() {
		const { tab } = this.state;
		const { isMainReseller } = this.injected.store;
		const r = this.injected.store.restaurant!;
		let options =
			[
				{ name: "Menus", value: "0" },
				{ name: "Option Sets", value: "1" },
				{ name: "Item Tags", value: "2" },
			]
		//
		return (
			<Content width="lg">

				<div className="flex-l-r-center m-b-6">
					<h1>Menus</h1>
					<div className="m-l-2 child-mr-10">
						<RestaurantMenuTemplates />
						{isMainReseller && <RestaurantMenuUploadService />}
						{isMainReseller && (
							<Tooltip text={"Help"} width={50} position={"top"}>
								<a target="_blank" href="https://support.cloudwaitress.com/getting-started/setup-your-menus">
									<Button size="xxs" color="white" className="p-lr-1">
										<FaQuestionCircle />
									</Button>
								</a>
							</Tooltip>
						)}
					</div>
				</div>

				<ButtonGroup
					size="sm"
					className="bsl-1"
					selected={this.state.tab}
					options={options}
					onSelect={(v) => this.setState({ tab: v.value as string || "0" })}
					width={100} />

				<div className="m-t-8">
					{tab === "0" && <RestaurantMenuList />}
					{tab === "1" && <RestaurantOptionSetList />}
					{tab === "2" && <RestaurantDishTags />}
				</div>

			</Content>
		);
	}

}
