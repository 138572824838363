import React from 'react';
import { MobxComponent } from "../../../mobx/components";
import { inject, observer } from "mobx-react";
import { HeaderContainer, HeaderMenuWrapper, LayoutContainer, MainContainer, MenuItem, SidebarContainer, SidebarLogoWrapper, SidebarMenuWrapper, HeaderMobile, HeaderMobileTitleWrapper, HeaderMobileLogoWrapper, HeaderMobileTitle, HeaderMobileMenuContainer, HeaderMobileMenuUpperWrapper, HeaderMobileMenuLowerWrapper, HeaderMobileMenuTitleWrapper, HeaderMobileMenuWrapper, HeaderTitleWrapper } from "./layout.c";
import { IconBell, IconBookings, IconCustomers, IconDashboard, IconDocument, IconLogout, IconMenus, IconOrders, IconPayment, IconSettings, IconStore, IconHamburger, IconClose, IconBack } from './icons';
import { withTranslation, WithTranslation } from "react-i18next";
import { routes } from '../../../routes';
import { RotateLoader } from '@lib/components';
import { CoreUtils } from '@lib/common';
import { Notifications } from '../../ui/restaurant/notifications';
import { AudioUnlock } from '../AudioUnlock';
import styled from 'styled-components';


const LayoutDashBoardWrapper = styled.div<{ customStyle?: string }>`
    padding: ${({ customStyle }) => (['orders', 'list', 'bump'].includes(customStyle || '') ? '0' : '2rem')};
    background-color: ${({ customStyle }) => (customStyle === 'orders'? '#fff'  : '')};
`
interface Props extends WithTranslation {
	children: React.ReactNode;
}
interface State {
    activeMobileMenu: boolean,
    activeSidebarMenu: boolean
}

@inject("store") @observer
class DashboardLayoutV2Class extends MobxComponent<Props, State> {
	constructor(props: Props) {
		super(props);
		this.state = {
            activeMobileMenu: false,
            activeSidebarMenu: false
        };
	}

    componentDidMount(): void {
        this.injected.store.service.restaurant.get();
    }

    toggleMobileMenu() {
        this.setState({
            activeMobileMenu: !this.state.activeMobileMenu,
        })
    }

    toggleSidebarMenu() {
        this.setState({
            activeSidebarMenu: !this.state.activeSidebarMenu,
        })
    }

    render () {
        const { store } = this.injected;
        const r = store.restaurant;
        const reseller = store.reseller;
        const { restrictions } = store;
	    const notificationsEnabled = restrictions._.restaurantNotificationsEnabled;
		// Should move to reseller setting section later
		let documentationLink = 'https://support.cloudwaitress.com';
        if (!r) {
            return (<RotateLoader />)
        }
        if (!reseller) {
            return (<RotateLoader />)
        }
        const logoTopNavs = r.website.sections.top_nav.images.logo;
        
		if (reseller._id === 'ceorestaurant') {
			documentationLink = 'https://support.ceorestaurant.my';
		}
        const { activeSidebarMenu, activeMobileMenu } = this.state;
        const shouldOnlinePaymentAllowed =
        !!r.settings.payments.stripe?.connect_account_id?.length &&
        restrictions._.onlinePaymentEnabled;
        const pathname = store.router.s.path;
        const pathNameLength = store.router.s.path.split("/").length -1 ;
		const pathNameEnd =  store.router.s.path.split("/")[pathNameLength] ;
        let links = [
			{
				id: "nav-link-home",
				tip: "Home",
				icon: <IconDashboard />,
				active: !!routes.restaurant_dashboard.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}`),
				allowed: restrictions.restaurant.dashboard,
			},
			{
                id: "nav-link-orders",
                tip: "Orders",
                icon: <IconOrders />,
                active: !!routes.restaurant_orders.pattern.match(pathname),
                onClick: () => {
                  if (restrictions.restaurant.orders_board) {
                    store.router.push(`/restaurant/${r._id}/orders`);
                  } else if (
                    !restrictions.restaurant.orders_board &&
                    (restrictions.restaurant.orders_bump || restrictions.restaurant.orders_list)
                  ) {
                    if (restrictions.restaurant.orders_bump) {
                      store.router.push(`/restaurant/${r._id}/orders/bump`);
                    } else if (restrictions.restaurant.orders_list) {
                      store.router.push(`/restaurant/${r._id}/orders/list`);
                    }
                  }
                },
                allowed: restrictions._.restaurantOrderViews.length > 0,
              },
			{
				id: "nav-link-bookings",
				tip: "Bookings",
				icon: <IconBookings />,
				active: !!routes.restaurant_bookings.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}/bookings`),
				allowed: restrictions.restaurant.bookings,
			},
			{
				id: "nav-link-customers",
				tip: "Customers",
				icon: <IconCustomers />,
				active: !!routes.restaurant_customers.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}/customers`),
				allowed: restrictions.restaurant.customers,
			},
			{
				id: "nav-link-menus",
				tip: "Menus",
				icon: <IconMenus />,
				active: !!routes.restaurant_menus.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}/menus`),
				allowed: restrictions.restaurant.menus,
			},
			{
				id: "nav-link-connect",
				tip: "Online Payments",
				icon: <IconPayment />,
				active: !!routes.restaurant_stripe_connect.pattern.match(pathname),
				onClick: () => { store.router.push(`/restaurant/${r._id}/online_payments`) },
				allowed: shouldOnlinePaymentAllowed,
			},
			{
				id: "nav-link-settings",
				tip: "Settings",
				icon: <IconSettings />,
				active: !!routes.restaurant_settings.pattern.match(pathname),
				onClick: () => store.router.push(`/restaurant/${r._id}/settings`),
				allowed: restrictions._.restaurantSettingsEnabled,
			},
			{
				id: "nav-link-view-store",
				tip: "View Store",
				icon: <IconStore />,
				active: false,
				onClick: () => {
					window.open(store.storeURL, "_blank");
				},
				allowed: restrictions._.restaurantSettingsEnabled || restrictions.restaurant.menus,
			},
			{
				id: "nav-link-documentation",
				tip: "Documentation",
				icon: <IconDocument />,
				active: false,
				onClick: () => {
					window.open(documentationLink, "_blank");
				},
				allowed: store.showMainSupport,
			},
		];
        return (
            <LayoutContainer>								
                <SidebarContainer className={`${activeSidebarMenu ? 'active': ''}`}>
                    <SidebarLogoWrapper className='sidebar-logo-wrapper'>
                        <HeaderTitleWrapper onClick={() => this.toggleSidebarMenu()}>
                            <img src={CoreUtils.image.uc(logoTopNavs, { resize: "x150", format: "auto" })} style={{ maxHeight: "40px" }} />
                            <span>{r.name || 'Acme Pizza'}</span>
                        </HeaderTitleWrapper>
                        <MenuItem onClick={() => this.toggleSidebarMenu()}><IconClose /></MenuItem>
                    </SidebarLogoWrapper>
                    <SidebarMenuWrapper className='sidebar-menu-wrapper'>
                        {links.map( link => !link.allowed ? null :
                            <MenuItem
                            key={link.id}
                             active={link.active}
                             onClick={link.onClick}>
                             {link.icon}
                             <span>{link.tip}</span>
                            </MenuItem>
                        )}
                    </SidebarMenuWrapper>
                </SidebarContainer>	
                <MainContainer>			
                    <HeaderContainer>
                        <HeaderMenuWrapper>
                            <MenuItem onClick={() => {
                                    store.router.push("/");
                                    store.notifications.ablyRestaurantStop();
                                }}>
                               <IconBack />
                            </MenuItem>
                            <span>{r.name || 'Acme Pizza'}</span>
                        </HeaderMenuWrapper>
                        <HeaderMenuWrapper>
                                <MenuItem>
                                {notificationsEnabled && <AudioUnlock className="m-r-5" />}
							    </MenuItem>
                                <MenuItem>
                            
                                {notificationsEnabled && <Notifications />}
                                
                                </MenuItem>
                                <MenuItem onClick={() => store.service.logout()}><IconLogout /></MenuItem>
                        </HeaderMenuWrapper>
                    </HeaderContainer>
                    <HeaderMobile>
                        <MenuItem 
                            onClick={() => this.toggleMobileMenu()}>
                            <IconHamburger />
                        </MenuItem>
                        <HeaderMobileTitleWrapper>
                            <HeaderMobileLogoWrapper>
                                <img src={CoreUtils.image.uc(logoTopNavs, { resize: "x150", format: "auto" })} style={{ maxHeight: "32px" }} />
                            </HeaderMobileLogoWrapper>
                            <HeaderMobileTitle>
                                {r.name || 'Acme Pizza'}
                            </HeaderMobileTitle>
                        </HeaderMobileTitleWrapper>
                        <MenuItem>
                            {notificationsEnabled && <Notifications />}  
                        </MenuItem>
                    </HeaderMobile>
                    <HeaderMobileMenuContainer className={`${activeMobileMenu ? 'active': ''}`}>
                        <HeaderMobileMenuUpperWrapper>
                            <HeaderMobileMenuTitleWrapper>
                                <HeaderMobileTitleWrapper>
                                    <HeaderMobileLogoWrapper>
                                    <img src={CoreUtils.image.uc(logoTopNavs, { resize: "x150", format: "auto" })} style={{ maxHeight: "32px" }} />
                                    </HeaderMobileLogoWrapper>
                                    <HeaderMobileTitle>
                                        {r.name || 'Acme Pizza'}
                                    </HeaderMobileTitle>
                                </HeaderMobileTitleWrapper>
                                <MenuItem onClick={() => this.toggleMobileMenu()}>
                                    <IconClose />
                                </MenuItem>
                            </HeaderMobileMenuTitleWrapper>
                            <HeaderMobileMenuWrapper>
                                {links.map( link => !link.allowed ? null :                   
                                <MenuItem
                                    key={link.id}
                                    active={link.active}
                                    onClick={ () => {
                                        this.setState({
                                            activeMobileMenu: false
                                        }, () => {
                                            link.onClick()
                                        })
                                      
                                    }}>
                                    {link.icon}
                                    <p>{link.tip}</p>
                                </MenuItem>
                                )}
                            </HeaderMobileMenuWrapper>
                        </HeaderMobileMenuUpperWrapper>
                        <HeaderMobileMenuUpperWrapper>
                            <HeaderMobileMenuWrapper>
                                <MenuItem onClick={() => store.service.logout()}>
                                    <IconLogout />
                                    Exit
                                </MenuItem>
                            </HeaderMobileMenuWrapper>
                        </HeaderMobileMenuUpperWrapper>
                    </HeaderMobileMenuContainer>
                    <LayoutDashBoardWrapper customStyle={pathNameEnd}>
                        {this.props.children}
                    </LayoutDashBoardWrapper>
                </MainContainer>
            </LayoutContainer>
        )
    }
}

export const DashboardLayoutV2 = withTranslation()(DashboardLayoutV2Class);
