import React, { useState, useEffect } from 'react';
import { CommonText } from '@lib/components';
import { MobXProviderContext } from 'mobx-react';

type CountdownClockProps = {
  time: number; // in seconds
  finishCountdown: (value: boolean) => void;
};
export const CountdownClock: React.FC<CountdownClockProps> = ({ time, finishCountdown }) => {
  const [countdownTime, setCountdownTime] = useState(time);
  const [displayTime, setDisplayTime] = useState('');
  const { store } = React.useContext(MobXProviderContext);
  const restaurantId = store.restaurant._id;
  if (time === 0) {
    return <></>;
  }
  useEffect(() => {
    if (countdownTime > 0) {
      let countdownInterval = setInterval(() => {
        setCountdownTime((prevTime: number) => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    }
  }, [countdownTime]);

  useEffect(() => {
    setCountdownTime(time);
  }, [time]);

  useEffect(() => {
    setDisplayTime(formatTime(countdownTime));

    if (countdownTime === 0) {
      finishCountdown(true);
    }
  }, [countdownTime]);

  const formatTime = (totalSeconds: number) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  return (
    <CommonText size="semiMedium" weight={500} color="#F5F5F5">
      Time Remaining: {displayTime}
    </CommonText>
  );
};
