import * as React from "react";
import { darken } from "polished";
import {
	styled,
	Input,
	CS,
	Tooltip,
	Button,
	InputGroup,
} from "@lib/components";
import {
	FaChevronRight,
	FaChevronDown,
	FaEdit,
	FaTrashAlt,
	FaCopy,
} from "react-icons/fa";
import { Draggable } from "react-beautiful-dnd";

const Wrapper = styled("div")``;

const Item = styled("div")<{ drag?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: move;
	cursor: ${({ drag }) => (drag ? "grab" : "pointer")};
	border-radius: 5px;
	font-size: 14px;
	font-weight: 500;
	width: 100%;
	color: ${({ theme }) => theme.box.text};
	background: ${({ theme }) => theme.box.background};
	padding: 0 0 0 16px;
	height: 43px;
	${CS.shadow.light.one};
	transition: 0.3s all;
	&:hover {
		background: ${darken(0.02, "white")};
	}
`;

export const ItemButton = styled(Button)`
	color: ${({ theme }) => darken(0.6, theme.box.background)};
	&:hover {
		color: ${({ theme }) => theme.box.text};
	}
`;

const SubWrapper = styled("div")`
	display: flex;
	justify-content: flex-end;
	padding-top: 6px;
`;

const SubContent = styled("div")`
	width: calc(100% - 25px);
`;

interface MenuListItemProps {
	id: string;
	type: string;
	name: React.ReactNode;
	index: number;
	mode: "normal" | "reOrder" | "editPrices";
	onCopy?: (index: number) => void;
	onEdit?: (index: number) => void;
	onRemove?: (index: number) => void;
	onClick?: (index: number) => void;
	onPriceEdit?: (price: number) => void;
	buttons?: React.ReactNode;
	children?: React.ReactNode;
	price?: number;
	currency?: T.Core.Business.BusinessCurrency & {
		step: string;
	};
}
interface MenuListItemState {
	active: boolean;
}

const toolTipWidth = 60;

export class MenuListItem extends React.PureComponent<
	MenuListItemProps,
	MenuListItemState
> {
	constructor(props: MenuListItemProps) {
		super(props);
		this.state = { active: false };
		this.toggleActive = this.toggleActive.bind(this);
	}

	toggleActive() {
		this.setState({ active: !this.state.active });
		if (this.props.onClick) {
			this.props.onClick(this.props.index);
		}
	}

	render() {
		const {
			id,
			type,
			name,
			index,
			onCopy,
			onEdit,
			onRemove,
			onPriceEdit,
			children,
			buttons,
			mode,
			price,
			currency,
		} = this.props;

		const { active } = this.state;

		return (
			<Draggable
				draggableId={id}
				index={index}
				type={type}
				isDragDisabled={mode !== "reOrder"}
			>
				{(provided) => (
					<Wrapper
						id={id}
						className="list-item"
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
					>
						<Item
							onClick={this.toggleActive}
							drag={mode === "reOrder"}
						>
							<div className="flex-line centered flex-grow">
								{!!children &&
									(active ? (
										<FaChevronDown className="m-r-3" />
									) : (
										<FaChevronRight className="m-r-3" />
									))}
								{name}
							</div>
							{mode === "normal" && (
								<div>
									{buttons}
									{onEdit && (
										<Tooltip
											text="Edit"
											width={toolTipWidth}
											position="top"
										>
											<ItemButton
												type="button"
												color="white"
												className="no-round no-border width32"
												paddinglr={5}
												onClick={(e) => {
													e.stopPropagation();
													onEdit(index);
												}}
											>
												<FaEdit />
											</ItemButton>
										</Tooltip>
									)}
									{onRemove && (
										<Tooltip
											text="Delete"
											width={toolTipWidth}
											position="top"
										>
											<ItemButton
												type="button"
												color="white"
												className="no-round no-border width32"
												paddinglr={5}
												onClick={(e) => {
													e.stopPropagation();
													onRemove(index);
												}}
											>
												<FaTrashAlt />
											</ItemButton>
										</Tooltip>
									)}
									{onCopy && (
										<Tooltip
											text="Copy"
											width={toolTipWidth}
											position="top"
										>
											<ItemButton
												type="button"
												color="white"
												className="no-round no-border width32"
												paddinglr={5}
												onClick={(e) => {
													e.stopPropagation();
													onCopy(index);
												}}
											>
												<FaCopy />
											</ItemButton>
										</Tooltip>
									)}
								</div>
							)}
							{mode === "editPrices" && type === "DISH" && (
								<>
									<InputGroup
										iconHtml={
											<div className="font-semi-bold">
												{currency!.symbol}
											</div>
										}
									></InputGroup>
									<Input
										type="number"
										step={currency!.step}
										style={{
											maxWidth: "15rem",
											marginRight: "0.2rem",
										}}
										min={0}
										required={true}
										value={price}
										onChange={(e) => {
											e.stopPropagation();

											if (
												e.target.validity.valid ||
												e.target.value === ""
											) {
												const newPrice = parseFloat(
													e.target.value
												);

												onPriceEdit!(newPrice || 0);
											}
										}}
									/>
								</>
							)}
						</Item>
						{children && active && (
							<SubWrapper>
								<SubContent>{children}</SubContent>
							</SubWrapper>
						)}
					</Wrapper>
				)}
			</Draggable>
		);
	}
}
