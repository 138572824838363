export const serviceOptionsConstant: TOptionItem[] = [
  {
    id: '1',
    text: 'Pickups',
    value: 'pickup',
    isActive: false,
  },
  {
    id: '2',
    text: 'Deliveries',
    value: 'delivery',
    isActive: false,
  },
  {
    id: '3',
    text: 'Dine-Ins',
    value: 'dine_in',
    isActive: false,
  },
  {
    id: '4',
    text: 'Table Bookings',
    value: 'booking',
    isActive: false,
  },
];

const calculateRemainingMinutesUntilEndOfDay = () => {
  const now = new Date();
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
  const timeDiff = endOfDay.getTime() - now.getTime();
  const remainingMinutes = Math.ceil(timeDiff / (1000 * 60));
  return remainingMinutes.toString();
};

export const durationOptionsConstant = [
  {
    id: '1',
    text: '20 min',
    value: '20',
    isActive: false,
  },
  {
    id: '2',
    text: '30 min',
    value: '30',
    isActive: false,
  },
  {
    id: '3',
    text: '60 min',
    value: '60',
    isActive: false,
  },
  // {
  //   id: '4',
  //   text: 'Rest of the Day',
  //   value: calculateRemainingMinutesUntilEndOfDay(),
  //   isActive: false,
  // },
];

export const reasonOptionsConstant = [
  {
    id: '1',
    text: 'Too Busy',
    value: 'too busy',
    isActive: false,
  },
  {
    id: '2',
    text: 'Closing Early',
    value: 'closing early',
    isActive: false,
  },
];
