import * as React from 'react';
import { Sidebar, css, styled, FooterSection, SidebarBodySection, SidebarBodySectionTop } from '@lib/components';
import { Button } from '@lib/components';
import { DeliveryIcon, DineinIcon, PickupIcon } from './bump-icon';
import { useState } from 'react';
import localStorage from 'store';
import { MobXProviderContext } from 'mobx-react';

const ServiceTypesHeader = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #4b4f5e;
`;
const ViewDeviceWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 16px;
`;
const ViewDeviceButton = styled.button<{ active: boolean }>`
  display: flex;
  padding: 8px 16px;
  align-items: center;
  border: 1px solid #4b4f5e;
  border-radius: 4px;
  font-weight: 600;
  min-width: 152px;
  min-height: 56px;
  ${({ active }) => {
    return active
      ? css`
          background: #fff3f3;
          color: #fb2f2f;
          border: 1px solid #fb2f2f;
          div,
          svg path {
            fill: #fb2f2f;
          }
        `
      : css`
          background: #f1f4f7;
          color: #4b4f5e;
          border: 1px solid transparent;
          div,
          svg path {
            color: #4b4f5e;
          }
        `;
  }}
`;

interface BumpViewScreenProps {
  active: boolean;
  close: () => void;
}

const DEFAULT_SERVICE = ['pickup', 'delivery', 'dine_in'];
export const FilterViewScreen: React.FC<BumpViewScreenProps> = ({ active, close }) => {
  const { store } = React.useContext(MobXProviderContext);
  const serviceToFilter = localStorage.get('serviceToFilter');
  const orderServiceDisplay = serviceToFilter ? serviceToFilter : DEFAULT_SERVICE;
  const [selectedCards, setSelectedCards] = useState<string[]>(orderServiceDisplay);
  const restaurantId = store.restaurant._id;

  const getOrdFilters = (store: any, restaurantId: string) => {
    const savedSettingBumpView = localStorage.get('settingBumpView');
    let savedActiveCard = savedSettingBumpView ? JSON.parse(savedSettingBumpView).card : 4;
    return store.api.orders_bump_find({
      page: 1,
      limit: savedActiveCard,
      sort: {
        created: -1,
      },
      query: {
        'config.service': {
          $in: selectedCards,
        },
        status: {
          $in: ['confirmed'],
        },
        restaurant_id: restaurantId,
      },
    });
  };

  const handleCardClick = (value: string) => {
    if (selectedCards.includes(value)) {
      setSelectedCards(selectedCards.filter(card => card !== value));
    } else {
      setSelectedCards([...selectedCards, value]);
    }
  };

  const handleApplyClick = async () => {
    localStorage.set('serviceToFilter', selectedCards);
    close();
    const response = await getOrdFilters(store, restaurantId);
    store.updateOrders({
      items: response.items,
      count: response.count,
      page: 1,
    });
  };

  return (
    <Sidebar isOpen={active} headerTitle="Filter" onClose={close}>
      <SidebarBodySection>
        <SidebarBodySectionTop>
          <ServiceTypesHeader className="m-t-2">Service Type</ServiceTypesHeader>
          <ViewDeviceWrapper>
            <ViewDeviceButton active={selectedCards.includes('delivery')} onClick={() => handleCardClick('delivery')}>
              <div style={{ marginRight: '3px' }}>
                <DeliveryIcon />
              </div>
              Delivery
            </ViewDeviceButton>
            <ViewDeviceButton active={selectedCards.includes('pickup')} onClick={() => handleCardClick('pickup')}>
              <div style={{ marginRight: '3px' }}>
                <PickupIcon />
              </div>
              Pickup
            </ViewDeviceButton>
            <ViewDeviceButton active={selectedCards.includes('dine_in')} onClick={() => handleCardClick('dine_in')}>
              <div style={{ marginRight: '3px' }}>
                <DineinIcon />
              </div>
              Dine In
            </ViewDeviceButton>
          </ViewDeviceWrapper>
        </SidebarBodySectionTop>
        <FooterSection>
          <Button full={true} type="button" color="primary" onClick={handleApplyClick}>
            Apply Filter
          </Button>
        </FooterSection>
      </SidebarBodySection>
    </Sidebar>
  );
};
